import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const Home = lazy(() => import("../pages/Principal/indexPrincipal.jsx"));

const Routes = () => {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Switch>
        <Route path="/" exact component={Home} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
