import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Global from "./styles/global";
import Routes from "./routes/Routes";
import TagManager from "react-gtm-module";

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      import("react-facebook-pixel")
        .then((e) => e.default)
        .then((ReactPixel) => {
          ReactPixel.init(process.env.REACT_APP_PIXEL);
          ReactPixel.trackCustom("PageViewLP");
        });
      TagManager.initialize({ gtmId: `${process.env.REACT_APP_GTM}` });
    }
  }, []);

  return (
    <Router>
      <Global />
      <Routes />
    </Router>
  );
};

export default App;
