import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    //Inpera
    --color-Branco: #FFFFFF;
    --color-Text: #2C2F38;
    --backgroundInpera: #d0944b;
    --backgroundInpera10: #faf4ed;
    --backgroundInpera45: #e8cba8;
    --background-Inpera: #d0944b;
    --corPrimaria10: #faf4ed;
    --corPrimaria45: #e8cba8;

    //Grid - CSS Puro
    --background-grid : #FAFAFC;
    --border-grid:#E6E6F0;
    --color-placeholder:#7E8086;
    --background-check: #F7F5FA;
    --border-check: #C4C4C4;


  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
    ::-webkit-scrollbar-track {
       background-color: #faf4ed;
     }
    ::-webkit-scrollbar {
       width: 0.2rem;
       height: 0.8rem;
       background: #d0944b;
     }
    ::-webkit-scrollbar-thumb {
       background: #d0944b;
     }
  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #f1eee3;
    color: #2C2F38;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    outline: none !important;
  }
`;
